import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { NODE_TYPES } from 'utils/constants';
import TextField from '@material-ui/core/TextField';
import TooltipTitle from 'components/Common/TooltipTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import ResourceSelection from 'components/Database/ResourcesSelection';
import { uniqueId } from 'lodash';
import Actions from '../../Common/Actions';
import { nodeTypeScroll, Paper } from '../../Styles';

export const WellIngestionPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  input_path: '',
  filetype: 'las',
  well_name: '',
  skiprows_log: 0,
  index_column: '',
  delimiter: ',',
  null_value: '',
  dev: '',
  well_type: 'Vertical',
  f: 'csv',
  td_filetype: 'txt',
  delim: 'whitespace',
  skiprows_dev: 0,
  calculated: 1,
  dept: 0,
  inc: 1,
  azi: 2,
  x_column: 1,
  y_column: 2,
  z: 3,
  x_coord: '-1',
  y_coord: '-1',
  e: '-1',
  log_range: '0,0',
  log_columns: '',
  log_columns_new: '',
  depth_spacing: 0.5,
  gap_limit: 1,
  seismic_domain: 'Depth',
  dt_path: '',
  sep: ',',
  md_col: 1,
  twt_col: 2,
  twt_units: '',
  skiprows_td: 0,
  nodeType: NODE_TYPES.WELL_INGESTION
};

function WellIngestion({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeScroll();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;
  const handleChangeWellType = (event) => {
    const wellType = event.target.value;
    if (wellType === 'Vertical') {
      setValues({ ...values, well_type: wellType, dev: '' });
    } else {
      setValues({ ...values, well_type: wellType });
    }
  };
  const handleChangeSeismicDomain = (event) => {
    const seismicDomain = event.target.value;
    if (seismicDomain === 'Depth') {
      setValues({ ...values, seismic_domain: seismicDomain, dt_path: '' });
    } else {
      setValues({ ...values, seismic_domain: seismicDomain });
    }
  };
  const handleChange = (prop) => (event) => {
    let { value } = event.target;
    if (
      (prop === 'skiprows_log' && value < 0) ||
      (prop === 'skiprows_dev' && value < 0) ||
      (prop === 'skiprows_td' && value < 0)
    )
      return;
    if (prop === 'gap_limit' && value < values.depth_spacing) return;
    if (prop === 'log_columns') value = value.toUpperCase();
    setValues({ ...values, [prop]: value });
  };

  const handleResourceChange = (prop) => (value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSave = () => {
    setSaveNode(true);
    let wellName = values.well_name;
    if (wellName.trim() === '') wellName = `well_${shortid.generate()}`;
    if (values.name !== '') {
      onSave({ ...values, well_name: wellName });
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="WellIngestion" className={classes.root}>
      <Paper>
        <TooltipTitle tooltip="Friendly name">Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="Provide a name/ID for your well. This will be used to identify the well
         throughout this workflow."
        >
          Workflow Well Identifier
        </TooltipTitle>
        <TextField
          margin="dense"
          id="well_name"
          type="text"
          fullWidth
          onChange={handleChange('well_name')}
          value={values.well_name}
        />
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="Select a well from QApp's internal database. If you can't find your well, 
        visit the data management menu and ensure that it has been uploaded properly."
        >
          Well Selection
        </TooltipTitle>
        <FormControl fullWidth>
          <ResourceSelection inputPath={values.input_path} onChange={handleResourceChange('input_path')} />
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="The original name of the log properties in the imported well log. 
        This needs to be a list seperated by commas."
        >
          Original Well Log Columns
        </TooltipTitle>
        <TextField
          margin="dense"
          id="log_columns"
          fullWidth
          onChange={handleChange('log_columns')}
          value={values.log_columns}
        />
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="The desired name for the log properties to be used in the modeling workflow. Names need to be
        consistant across wells."
        >
          Modified Well Log Column Names
        </TooltipTitle>
        <TextField
          margin="dense"
          id="log_columns_new"
          fullWidth
          onChange={handleChange('log_columns_new')}
          value={values.log_columns_new}
        />
      </Paper>
      <Paper>
        <TooltipTitle tooltip="Desired sample rate of your log, in the units of the original log.">
          Desired Log Spacing
        </TooltipTitle>
        <TextField
          margin="dense"
          id="depth_spacing"
          type="number"
          fullWidth
          onChange={handleChange('depth_spacing')}
          value={values.depth_spacing}
          InputProps={{ inputProps: { min: 0.01, max: 10 } }}
        />
      </Paper>
      <Paper>
        <TooltipTitle tooltip="Threshold for interpolation. Any gap larger than the gap limit will not be interpolated">
          Interpolation Gap Limit
        </TooltipTitle>
        <TextField
          margin="dense"
          id="gap_limit"
          type="number"
          fullWidth
          onChange={handleChange('gap_limit')}
          value={values.gap_limit}
          helperText="Must be equal or greater than Depth Spacing"
          InputProps={{ inputProps: { min: values.depth_spacing, max: 10 } }}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Well Surface X-Coordinate</TooltipTitle>
        <TextField
          margin="dense"
          id="x_coord"
          type="number"
          fullWidth
          onChange={handleChange('x_coord')}
          value={values.x_coord}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Well Surface Y-Coordinate</TooltipTitle>
        <TextField
          margin="dense"
          id="y_coord"
          type="number"
          fullWidth
          onChange={handleChange('y_coord')}
          value={values.y_coord}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Well Kelly Bushing Elevation</TooltipTitle>
        <TextField margin="dense" id="e" type="number" fullWidth onChange={handleChange('e')} value={values.e} />
      </Paper>
      <Paper>
        <TooltipTitle>File Type</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={values.filetype}
            onChange={handleChange('filetype')}
          >
            <MenuItem value="las">LAS File (Log ASCII Standard)</MenuItem>
            <MenuItem value="csv">CSV (*csv)</MenuItem>
            <MenuItem value="txt">Text (*txt)</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      {values.filetype !== 'las' && (
        <>
          <Paper>
            <TooltipTitle>Depth Column Number</TooltipTitle>
            <TextField
              margin="dense"
              id="index_column"
              type="number"
              fullWidth
              value={values.index_column}
              InputProps={{ inputProps: { min: 0, max: 999, step: 1 } }}
              onChange={handleChange('index_column')}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Skip Rows</TooltipTitle>
            <TextField
              margin="dense"
              id="skiprows_log"
              type="number"
              value={values.skiprows_log}
              fullWidth
              onChange={handleChange('skiprows_log')}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Delimiter</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="delimiter-select-label"
                id="delimiter-select"
                value={values.delimiter}
                onChange={handleChange('delimiter')}
              >
                <MenuItem value="\t">Tab</MenuItem>
                <MenuItem value=",">Comma</MenuItem>
                <MenuItem value="whitespace">Whitespace</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>Null Value</TooltipTitle>
            <TextField
              margin="dense"
              id="null_value"
              label="Null Value"
              fullWidth
              onChange={handleChange('null_value')}
              value={values.null_value}
            />
          </Paper>
        </>
      )}
      <Divider style={{ marginTop: '40px', marginBottom: '40px' }} />
      <Paper>
        <TooltipTitle>What type of well is this?</TooltipTitle>
        <FormControl component="fieldset">
          <RadioGroup aria-label="well_type" name="well_type" value={values.well_type} onChange={handleChangeWellType}>
            <div style={{ display: 'flex' }}>
              <FormControlLabel value="Vertical" control={<Radio />} label="Vertical" />
              <FormControlLabel value="Deviated" control={<Radio />} label="Deviated" />
            </div>
          </RadioGroup>
        </FormControl>
      </Paper>
      {values.well_type !== 'Vertical' && (
        <>
          <Paper>
            <TooltipTitle>Deviation File</TooltipTitle>
            <FormControl fullWidth>
              <ResourceSelection inputPath={values.dev} onChange={handleResourceChange('dev')} />
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>File Type</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="dev-type-select-label"
                id="dev-type-select"
                value={values.f}
                onChange={handleChange('f')}
              >
                <MenuItem value="las">LAS File (Log ASCII Standard)</MenuItem>
                <MenuItem value="csv">CSV (*csv)</MenuItem>
                <MenuItem value="txt">Text (*txt)</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>File Delimiter</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="delimiter-select-label"
                id="delimiter-select"
                value={values.delim}
                onChange={handleChange('delim')}
              >
                <MenuItem value="\t">Tab</MenuItem>
                <MenuItem value=",">Comma</MenuItem>
                <MenuItem value="whitespace">Whitespace</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>Skip Rows</TooltipTitle>
            <TextField
              margin="dense"
              id="skiprows_dev"
              type="number"
              value={values.skiprows_dev}
              fullWidth
              onChange={handleChange('skiprows_dev')}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Survey Type</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="calculated-select-label"
                id="calculated-select"
                value={values.calculated}
                onChange={handleChange('calculated')}
              >
                <MenuItem value={0}>Directional</MenuItem>
                <MenuItem value={1}>XYZ</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>Depth Column Number</TooltipTitle>
            <TextField
              margin="dense"
              id="dept"
              type="number"
              value={values.dept}
              InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
              fullWidth
              onChange={handleChange('dept')}
            />
          </Paper>
          {values.calculated === 0 && (
            <>
              <Paper>
                <TooltipTitle>Inclination Column Number</TooltipTitle>
                <TextField
                  margin="dense"
                  id="inc"
                  type="number"
                  value={values.inc}
                  InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
                  fullWidth
                  onChange={handleChange('inc')}
                />
              </Paper>
              <Paper>
                <TooltipTitle>Azimuth Column Number</TooltipTitle>
                <TextField
                  margin="dense"
                  id="azi"
                  type="number"
                  value={values.azi}
                  InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
                  fullWidth
                  onChange={handleChange('azi')}
                />
              </Paper>
            </>
          )}
          {values.calculated === 1 && (
            <>
              <Paper>
                <TooltipTitle>X-Coordinate Column Number</TooltipTitle>
                <TextField
                  margin="dense"
                  id="x_column"
                  type="number"
                  value={values.x_column}
                  InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
                  fullWidth
                  onChange={handleChange('x_column')}
                />
              </Paper>
              <Paper>
                <TooltipTitle>Y-Coordinate Column Number</TooltipTitle>
                <TextField
                  margin="dense"
                  id="y_column"
                  type="number"
                  value={values.y_column}
                  InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
                  fullWidth
                  onChange={handleChange('y_column')}
                />
              </Paper>
              <Paper>
                <TooltipTitle>Z-Coordinate Column Number</TooltipTitle>
                <TextField
                  margin="dense"
                  id="z"
                  type="number"
                  value={values.z}
                  InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
                  fullWidth
                  onChange={handleChange('z')}
                />
              </Paper>
            </>
          )}
        </>
      )}
      <Divider style={{ marginTop: '40px', marginBottom: '40px' }} />
      <Paper>
        <TooltipTitle>Is the seismic data in depth or time domain?</TooltipTitle>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="seismic_domain"
            name="seismic_domain"
            value={values.seismic_domain}
            onChange={handleChangeSeismicDomain}
          >
            <div style={{ display: 'flex' }}>
              <FormControlLabel value="Depth" control={<Radio />} label="Depth" />
              <FormControlLabel value="Time" control={<Radio />} label="Time" />
            </div>
          </RadioGroup>
        </FormControl>
      </Paper>
      {values.seismic_domain !== 'Depth' && (
        <>
          <Paper>
            <TooltipTitle>Depth-Time File</TooltipTitle>
            <FormControl fullWidth>
              <ResourceSelection inputPath={values.dt_path} onChange={handleResourceChange('dt_path')} />
            </FormControl>
          </Paper>
          <Paper>
            <TooltipTitle>File Type</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="td-type-select-label"
                id="td-type-select"
                value={values.td_filetype}
                onChange={handleChange('td_filetype')}
              >
                <MenuItem value="las">LAS File (Log ASCII Standard)</MenuItem>
                <MenuItem value="txt">Text (*txt)</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          {values.td_filetype !== 'las' && (
            <>
              <Paper>
                <TooltipTitle>File Delimiter</TooltipTitle>
                <FormControl fullWidth>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={values.sep}
                    onChange={handleChange('sep')}
                  >
                    <MenuItem value="\t">Tab</MenuItem>
                    <MenuItem value=",">Comma</MenuItem>
                    <MenuItem value="whitespace">Whitespace</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
              <Paper>
                <TooltipTitle>Skip Rows</TooltipTitle>
                <TextField
                  margin="dense"
                  id="skiprows_td"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 99, step: 1 } }}
                  value={values.skiprows_td}
                  onChange={handleChange('skiprows_td')}
                />
              </Paper>
            </>
          )}

          <Paper>
            <TooltipTitle>Measured Depth Column Number</TooltipTitle>
            <TextField
              margin="dense"
              id="md_col"
              type="number"
              fullWidth
              value={values.md_col}
              InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
              onChange={handleChange('md_col')}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Two-Way-Time Column Number</TooltipTitle>
            <TextField
              margin="dense"
              id="twt_col"
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 99, step: 1 } }}
              value={values.twt_col}
              onChange={handleChange('twt_col')}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Two-Way-Time Units</TooltipTitle>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="twt_units"
                name="twt_units"
                value={values.twt_units}
                onChange={handleChange('twt_units')}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="ms" control={<Radio />} label="ms" />
                  <FormControlLabel value="s" control={<Radio />} label="s" />
                </div>
              </RadioGroup>
            </FormControl>
          </Paper>
        </>
      )}
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

WellIngestion.propTypes = WellIngestionPropTypes;
WellIngestion.defaultProps = {};

export default WellIngestion;
